.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('/Users/damorano/Documents/kolade/my-landing-page/src/images/background-image.jpg'); /* Replace with the path to your background image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .logo {
    width: 200px; /* Increase the width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 50px; /* Add margin from the top */
  }
  
  .heading {
    font-size: 48px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .button-container {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap */
    gap: 15px; /* Add spacing between buttons */
    justify-content: center; /* Center align buttons */
  }
  
  .button {
    padding: 15px 30px;
    border: 2px solid transparent;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    transition: all 0.3s ease;
  }
  
  .button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: #fff;
  }
  
  .whatsapp-button {
    background-color: #25d366;
  }
  
  .instagram-button {
    background-color: #e1306c;
  }
  
  .email-button {
    background-color: #3498db;
  }

  .snapchat-button {
    background-color: #fffc00; /* Yellow color for Snapchat button */
    color: #000; /* Black text color */

  }
/* Copyright notice */
.copyrig‌​ht {
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px; /* Add margin from the bottom */
  }
